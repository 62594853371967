var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "rootVM" } }, [
    _vm._m(0),
    _c("div", { staticClass: "panel panel-flat" }, [
      _c(
        "div",
        {
          staticClass: "panel-body",
          staticStyle: {
            width: "1100px",
            margin: "0px 20px -20px auto",
            padding: "20px 0px"
          }
        },
        [
          _c(
            "form",
            {
              attrs: { action: "#" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.search.apply(null, arguments)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-md-3",
                    staticStyle: { width: "300px", "margin-left": "10px" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [
                          _vm._v(_vm._s(_vm.detailsFieldMap.siteNm))
                        ]),
                        _c(
                          "select2",
                          {
                            attrs: { disabled: _vm.isSiteDisabled },
                            model: {
                              value: _vm.searchVM.siteNm,
                              callback: function($$v) {
                                _vm.$set(_vm.searchVM, "siteNm", $$v)
                              },
                              expression: "searchVM.siteNm"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("전체")
                            ]),
                            _vm._l(_vm.options.siteOptions, function(
                              row,
                              index
                            ) {
                              return _c(
                                "option",
                                { key: index, domProps: { value: row.siteNm } },
                                [_vm._v(_vm._s(row.siteNm))]
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-md-3",
                    staticStyle: { width: "200px", "margin-right": "10px" }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v(_vm._s(_vm.detailsFieldMap.cctvNm))]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchVM.cctvNm,
                            expression: "searchVM.cctvNm"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "startInput",
                          placeholder: _vm.detailsFieldMap.cctvNm,
                          maxlength: _vm.maxLength.cctvNm
                        },
                        domProps: { value: _vm.searchVM.cctvNm },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.searchVM,
                              "cctvNm",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3", staticStyle: { width: "200px" } },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [
                          _vm._v(_vm._s(_vm.detailsFieldMap.useYn))
                        ]),
                        _c(
                          "select2",
                          {
                            attrs: { options: _vm.options.ynOptions },
                            model: {
                              value: _vm.searchVM.useYn,
                              callback: function($$v) {
                                _vm.$set(_vm.searchVM, "useYn", $$v)
                              },
                              expression: "searchVM.useYn"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("전체")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _vm._m(1)
              ])
            ]
          )
        ]
      )
    ]),
    _c(
      "div",
      [
        _c("div", { staticClass: "mb-10 text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-labeled bg-teal mx-sm-1",
              attrs: { type: "button" },
              on: { click: _vm.downloadExcel }
            },
            [_vm._m(2), _vm._v(" 엑셀다운로드 ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-labeled bg-primary",
              attrs: { type: "button" },
              on: { click: _vm.startCreate }
            },
            [_vm._m(3), _vm._v(" 추가 ")]
          )
        ]),
        _c("KendoGrid", {
          ref: "grid",
          attrs: {
            "auto-bind": true,
            "api-url": _vm.apiUrl.pageListApi,
            columns: _vm.gridColumns,
            "apply-search-condition": _vm.applySearchStateOnGridLoad
          },
          on: { "selected-row-item-changed": _vm.selectedRowItemChanged }
        }),
        _c("div", { staticClass: "mt-10 mb-15 text-right" })
      ],
      1
    ),
    _c(
      "form",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isEditMode || _vm.isCreateMode,
            expression: "isEditMode || isCreateMode"
          }
        ],
        staticClass: "form-horizontal form-validate-jquery",
        attrs: { id: "detailsForm", action: "#" }
      },
      [
        _c("div", { staticClass: "panel panel-flat" }, [
          _c("div", { staticClass: "panel-heading" }, [
            _vm._m(4),
            _c("div", { staticClass: "heading-elements" }, [
              _c("ul", { staticClass: "icons-list" }, [
                _c("li", [
                  _c("a", {
                    attrs: { "data-action": "collapse" },
                    on: { click: _vm.pannelHidden }
                  })
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "panel-body" }, [
            _c("div", { staticClass: "row in-panel-body" }, [
              _c("fieldset", [
                _vm._m(5),
                _c(
                  "div",
                  { staticClass: "col-lg-6 inputDiv1" },
                  [
                    _vm.isEditMode
                      ? _c("jarvis-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.validationRule.detailsItem.cctvId,
                              expression: "validationRule.detailsItem.cctvId"
                            }
                          ],
                          attrs: {
                            label: _vm.detailsFieldMap.cctvId,
                            required: "true",
                            disabled: !_vm.isCreateMode,
                            field: "detailsItem.cctvId",
                            "data-vv-name": "detailsItem.cctvId"
                          },
                          model: {
                            value: _vm.detailsItem.cctvId,
                            callback: function($$v) {
                              _vm.$set(_vm.detailsItem, "cctvId", $$v)
                            },
                            expression: "detailsItem.cctvId"
                          }
                        })
                      : _vm._e(),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.validationRule.detailsItem.cctvNm,
                          expression: "validationRule.detailsItem.cctvNm"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.cctvNm,
                        id: "firstInputCreate",
                        required: "true",
                        field: "detailsItem.cctvNm",
                        "data-vv-name": "detailsItem.cctvNm",
                        maxLength: _vm.maxLength.cctvNm
                      },
                      model: {
                        value: _vm.detailsItem.cctvNm,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "cctvNm", $$v)
                        },
                        expression: "detailsItem.cctvNm"
                      }
                    }),
                    _c(
                      "jarvis-field",
                      {
                        attrs: {
                          label: _vm.detailsFieldMap.siteNm,
                          field: "detailsItem.siteNm",
                          required: "true"
                        }
                      },
                      [
                        _c(
                          "select2",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: _vm.validationRule.detailsItem.siteId,
                                expression: "validationRule.detailsItem.siteId"
                              }
                            ],
                            attrs: {
                              "data-vv-name": "detailsItem.siteNm",
                              options: _vm.options.siteOptions
                            },
                            model: {
                              value: _vm.detailsItem.siteId,
                              callback: function($$v) {
                                _vm.$set(_vm.detailsItem, "siteId", $$v)
                              },
                              expression: "detailsItem.siteId"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("선택")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "jarvis-field",
                      {
                        attrs: {
                          label: _vm.detailsFieldMap.cctvInstallDvsnCd,
                          field: "detailsItem.cctvInstallDvsnCd",
                          required: "true"
                        }
                      },
                      [
                        _c(
                          "select2",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value:
                                  _vm.validationRule.detailsItem
                                    .cctvInstallDvsnCd,
                                expression:
                                  "validationRule.detailsItem.cctvInstallDvsnCd"
                              }
                            ],
                            attrs: {
                              "data-vv-name": "detailsItem.cctvInstallDvsnCd",
                              options: _vm.options.cctvInstallDvsnCdOptions
                            },
                            model: {
                              value: _vm.detailsItem.cctvInstallDvsnCd,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.detailsItem,
                                  "cctvInstallDvsnCd",
                                  $$v
                                )
                              },
                              expression: "detailsItem.cctvInstallDvsnCd"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("선택")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value:
                            _vm.validationRule.detailsItem.cctvInstallPlaceCont,
                          expression:
                            "validationRule.detailsItem.cctvInstallPlaceCont"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.cctvInstallPlaceCont,
                        field: "detailsItem.cctvInstallPlaceCont",
                        required: "true",
                        "data-vv-name": "detailsItem.cctvInstallPlaceCont",
                        maxLength: _vm.maxLength.cctvInstallPlaceCont
                      },
                      model: {
                        value: _vm.detailsItem.cctvInstallPlaceCont,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "cctvInstallPlaceCont", $$v)
                        },
                        expression: "detailsItem.cctvInstallPlaceCont"
                      }
                    }),
                    _c(
                      "jarvis-field",
                      {
                        attrs: {
                          label: _vm.detailsFieldMap.useYn,
                          field: "detailsItem.useYn",
                          required: "true"
                        }
                      },
                      [
                        _c(
                          "select2",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: _vm.validationRule.detailsItem.useYn,
                                expression: "validationRule.detailsItem.useYn"
                              }
                            ],
                            attrs: {
                              "data-vv-name": "detailsItem.useYn",
                              options: _vm.options.ynOptions
                            },
                            model: {
                              value: _vm.detailsItem.useYn,
                              callback: function($$v) {
                                _vm.$set(_vm.detailsItem, "useYn", $$v)
                              },
                              expression: "detailsItem.useYn"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("선택")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "jarvis-field",
                      { attrs: { label: _vm.detailsFieldMap.cctvKindDvsnCd } },
                      [
                        _c(
                          "select2",
                          {
                            attrs: {
                              options: _vm.options.cctvKindDvsnCdOptions
                            },
                            model: {
                              value: _vm.detailsItem.cctvKindDvsnCd,
                              callback: function($$v) {
                                _vm.$set(_vm.detailsItem, "cctvKindDvsnCd", $$v)
                              },
                              expression: "detailsItem.cctvKindDvsnCd"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("선택")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.validationRule.detailsItem.cctvResolution,
                          expression:
                            "validationRule.detailsItem.cctvResolution"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.cctvResolution,
                        field: "detailsItem.cctvResolution",
                        "data-vv-name": "detailsItem.cctvResolution",
                        maxLength: _vm.maxLength.cctvResolution
                      },
                      model: {
                        value: _vm.detailsItem.cctvResolution,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "cctvResolution", $$v)
                        },
                        expression: "detailsItem.cctvResolution"
                      }
                    }),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.validationRule.detailsItem.cctvConnUrl,
                          expression: "validationRule.detailsItem.cctvConnUrl"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.cctvConnUrl,
                        field: "detailsItem.cctvConnUrl",
                        "data-vv-name": "detailsItem.cctvConnUrl",
                        maxLength: _vm.maxLength.cctvConnUrl
                      },
                      model: {
                        value: _vm.detailsItem.cctvConnUrl,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "cctvConnUrl", $$v)
                        },
                        expression: "detailsItem.cctvConnUrl"
                      }
                    }),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.validationRule.detailsItem.cctvConnPort,
                          expression: "validationRule.detailsItem.cctvConnPort"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.cctvConnPort,
                        field: "detailsItem.cctvConnPort",
                        "data-vv-name": "detailsItem.cctvConnPort",
                        maxLength: _vm.maxLength.cctvConnPort
                      },
                      model: {
                        value: _vm.detailsItem.cctvConnPort,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "cctvConnPort", $$v)
                        },
                        expression: "detailsItem.cctvConnPort"
                      }
                    }),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.validationRule.detailsItem.cctvPlayUrl,
                          expression: "validationRule.detailsItem.cctvPlayUrl"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.cctvPlayUrl,
                        field: "detailsItem.cctvPlayUrl",
                        "data-vv-name": "detailsItem.cctvPlayUrl",
                        maxLength: _vm.maxLength.cctvPlayUrl
                      },
                      model: {
                        value: _vm.detailsItem.cctvPlayUrl,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "cctvPlayUrl", $$v)
                        },
                        expression: "detailsItem.cctvPlayUrl"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-lg-6",
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                      height: "628px"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "col-lg-11 col-lg-offset-1",
                        staticStyle: {
                          height: "140px",
                          border: "1px solid #ddd",
                          "margin-bottom": "35px"
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "icon-question4",
                          staticStyle: {
                            color: "gray",
                            position: "absolute",
                            top: "20px"
                          },
                          on: {
                            mouseover: function($event) {
                              return _vm.info($event)
                            },
                            mouseleave: function($event) {
                              return _vm.closeInfo($event)
                            }
                          }
                        }),
                        _vm._m(6),
                        _c(
                          "div",
                          { staticStyle: { padding: "50px 0px 50px 0px" } },
                          [
                            _c(
                              "jarvis-field",
                              {
                                attrs: {
                                  label:
                                    _vm.detailsFieldMap.safeObjectDistigshYn,
                                  field: "detailsItem.safeObjectDistigshYn",
                                  required: "true"
                                }
                              },
                              [
                                _c(
                                  "select2",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value:
                                          _vm.validationRule.detailsItem
                                            .safeObjectDistigshYn,
                                        expression:
                                          "validationRule.detailsItem.safeObjectDistigshYn"
                                      }
                                    ],
                                    attrs: {
                                      required: true,
                                      "data-vv-name":
                                        "detailsItem.safeObjectDistigshYn",
                                      options: _vm.options.ynOptions
                                    },
                                    model: {
                                      value:
                                        _vm.detailsItem.safeObjectDistigshYn,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.detailsItem,
                                          "safeObjectDistigshYn",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "detailsItem.safeObjectDistigshYn"
                                    }
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v("선택")
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.validationRule.detailsItem.gpuNo,
                          expression: "validationRule.detailsItem.gpuNo"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.gpuNo,
                        field: "detailsItem.gpuNo",
                        required: "true",
                        "data-vv-name": "detailsItem.gpuNo"
                      },
                      model: {
                        value: _vm.detailsItem.gpuNo,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "gpuNo", $$v)
                        },
                        expression: "detailsItem.gpuNo"
                      }
                    }),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value:
                            _vm.validationRule.detailsItem.gpuParallelCount,
                          expression:
                            "validationRule.detailsItem.gpuParallelCount"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.gpuParallelCount,
                        field: "detailsItem.gpuParallelCount",
                        required: "true",
                        "data-vv-name": "detailsItem.gpuParallelCount"
                      },
                      model: {
                        value: _vm.detailsItem.gpuParallelCount,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "gpuParallelCount", $$v)
                        },
                        expression: "detailsItem.gpuParallelCount"
                      }
                    }),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.validationRule.detailsItem.mdserverId,
                          expression: "validationRule.detailsItem.mdserverId"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.mdserverId,
                        required: "true",
                        field: "detailsItem.mdserverId",
                        "data-vv-name": "detailsItem.mdserverId",
                        maxLength: _vm.maxLength.mdserverId
                      },
                      model: {
                        value: _vm.detailsItem.mdserverId,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "mdserverId", $$v)
                        },
                        expression: "detailsItem.mdserverId"
                      }
                    }),
                    _c(
                      "jarvis-field",
                      {
                        attrs: {
                          label: _vm.detailsFieldMap.mlEngineDvsnCd,
                          field: "detailsItem.mlEngineDvsnCd",
                          required: "true"
                        }
                      },
                      [
                        _c(
                          "select2",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value:
                                  _vm.validationRule.detailsItem.mlEngineDvsnCd,
                                expression:
                                  "validationRule.detailsItem.mlEngineDvsnCd"
                              }
                            ],
                            attrs: {
                              "data-vv-name": "detailsItem.mlEngineDvsnCd",
                              options: _vm.options.mlEngineDvsnCdOptions
                            },
                            model: {
                              value: _vm.detailsItem.mlEngineDvsnCd,
                              callback: function($$v) {
                                _vm.$set(_vm.detailsItem, "mlEngineDvsnCd", $$v)
                              },
                              expression: "detailsItem.mlEngineDvsnCd"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("선택")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.isEditMode
                      ? _c(
                          "div",
                          [
                            _vm.isEditMode
                              ? _c("jarvis-field", {
                                  attrs: {
                                    label: _vm.detailsFieldMap.regDtm,
                                    disabled: "true",
                                    field: "detailsItem.regDtm",
                                    value: _vm._f("toDisplayDateTime")(
                                      _vm.detailsItem.regDtm
                                    )
                                  }
                                })
                              : _vm._e(),
                            _vm.isEditMode
                              ? _c("jarvis-field", {
                                  attrs: {
                                    label: _vm.detailsFieldMap.regUser,
                                    disabled: "true",
                                    field: "detailsItem.regUser",
                                    value: _vm.detailsItem.regUser
                                  }
                                })
                              : _vm._e(),
                            _vm.isEditMode
                              ? _c("jarvis-field", {
                                  attrs: {
                                    label: _vm.detailsFieldMap.updDtm,
                                    disabled: "true",
                                    field: "detailsItem.updDtm",
                                    value: _vm._f("toDisplayDateTime")(
                                      _vm.detailsItem.updDtm
                                    )
                                  }
                                })
                              : _vm._e(),
                            _vm.isEditMode
                              ? _c("jarvis-field", {
                                  attrs: {
                                    label: _vm.detailsFieldMap.updUser,
                                    disabled: "true",
                                    field: "detailsItem.updUser",
                                    value: _vm.detailsItem.updUser
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ]),
            _c("div", [
              _vm._m(7),
              !_vm.isEditMode
                ? _c("div", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-labeled mx-sm-1",
                        attrs: { type: "button" },
                        on: { click: _vm.closeDetails }
                      },
                      [_vm._m(8), _vm._v(" 닫기 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-labeled bg-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.createData }
                      },
                      [_vm._m(9), _vm._v(" 저장 ")]
                    )
                  ])
                : _vm._e(),
              _vm.isEditMode
                ? _c("div", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-labeled mx-sm-1",
                        attrs: { type: "button" },
                        on: { click: _vm.closeDetails }
                      },
                      [_vm._m(10), _vm._v(" 닫기 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-labeled bg-warning mx-sm-1",
                        attrs: { type: "button" },
                        on: { click: _vm.deleteData }
                      },
                      [_vm._m(11), _vm._v(" 삭제 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-labeled bg-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.updateData }
                      },
                      [_vm._m(12), _vm._v(" 저장 ")]
                    )
                  ])
                : _vm._e()
            ]),
            _vm.debug
              ? _c("span", [
                  _vm._v(" isCreateMode: " + _vm._s(_vm.isCreateMode) + " "),
                  _c("br"),
                  _vm._v(" isEditMode: " + _vm._s(_vm.isEditMode) + " "),
                  _c("br"),
                  _vm._v(
                    " detailsItemOriginal: " +
                      _vm._s(_vm.detailsItemOriginal) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(" detailsItem: " + _vm._s(_vm.detailsItem) + " "),
                  _c("br"),
                  _vm._v(" errors: " + _vm._s(_vm.errors) + " "),
                  _c("br")
                ])
              : _vm._e()
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "pageTitle" } }, [
      _c("b", [_vm._v("CCTV관리")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-10 text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-labeled bg-primary",
          attrs: { type: "submit" }
        },
        [_c("b", [_c("i", { staticClass: "icon-search4" })]), _vm._v(" 검색 ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-file-excel" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-plus3" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", { staticClass: "panel-title" }, [
      _c("i", { staticClass: "icon-checkmark3 position-left" }),
      _c("b", [_vm._v("상세정보")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "text-semibold" }, [
      _c("i", { staticClass: "icon-cog3 position-left" }),
      _vm._v(" CCTV 정보 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tooltipText" }, [
      _vm._v(" *사용: 대상물이 안전한 상태인 경우에도 표시합니다. "),
      _c("br"),
      _vm._v(" *미사용: 대상물이 안전한 상태인 경우에는 표시하지 않습니다. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "font-size": "12px",
          color: "red",
          "margin-bottom": "10px"
        }
      },
      [
        _vm._v(
          " CCTV 접속 URL, CCTV 접속 포트, CCTV PLAY URL, gpu번호, gpu병렬개수, 미디어서버ID, ML엔진 등의 경우 ML서버 재시작 후 추가 및 수정 내역이 반영됩니다. "
        ),
        _c("br"),
        _vm._v(
          " 원활한 사용을 위해 CCTV 정보 추가 및 수정 후엔 ML서버를 재시작해주세요. "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-cross" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-checkmark3" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-cross" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-minus3" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-checkmark3" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }