<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>CCTV관리</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body" style="width: 1100px; margin: 0px 20px -20px auto; padding: 20px 0px">
				<form action="#" @submit.prevent="search">
					<div class="row">
						<div class="col-md-3" style="width: 300px; margin-left: 10px">
							<div class="form-group">
								<label>{{ detailsFieldMap.siteNm }}</label>
								<select2 v-model="searchVM.siteNm" :disabled="isSiteDisabled">
									<option value="">전체</option>
									<option v-for="(row, index) in options.siteOptions" :key="index" :value="row.siteNm">{{ row.siteNm }}</option>
								</select2>
							</div>
						</div>
						<!-- CCTV명 -->
						<div class="col-md-3" style="width: 200px; margin-right: 10px">
							<div class="form-group">
								<label>{{ detailsFieldMap.cctvNm }}</label>
								<input
									type="text"
									id="startInput"
									class="form-control"
									:placeholder="detailsFieldMap.cctvNm"
									v-model="searchVM.cctvNm"
									:maxlength="maxLength.cctvNm"
								/>
							</div>
						</div>
						<!-- 사용여부 -->
						<div class="col-md-3" style="width: 200px">
							<div class="form-group">
								<label>{{ detailsFieldMap.useYn }}</label>
								<select2 v-model="searchVM.useYn" :options="options.ynOptions">
									<option value="">전체</option>
								</select2>
							</div>
						</div>
						<!-- 검색 버튼 -->
						<div class="mb-10 text-right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal mx-sm-1" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
				<button type="button" class="btn btn-labeled bg-primary" @click="startCreate">
					<b><i class="icon-plus3"></i></b>
					추가
				</button>
			</div>

			<KendoGrid
				ref="grid"
				:auto-bind="true"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode || isCreateMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body">
					<div class="row in-panel-body">
						<fieldset>
							<legend class="text-semibold">
								<i class="icon-cog3 position-left"></i>
								CCTV 정보
							</legend>
							<div class="col-lg-6 inputDiv1">
								<!-- CCTV ID, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.cctvId"
									v-model="detailsItem.cctvId"
									required="true"
									:disabled="!isCreateMode"
									v-if="isEditMode"
									field="detailsItem.cctvId"
									data-vv-name="detailsItem.cctvId"
									v-validate="validationRule.detailsItem.cctvId"
								></jarvis-field>
								<!-- CCTV명 -->
								<jarvis-field
									:label="detailsFieldMap.cctvNm"
									id="firstInputCreate"
									v-model="detailsItem.cctvNm"
									required="true"
									field="detailsItem.cctvNm"
									data-vv-name="detailsItem.cctvNm"
									v-validate="validationRule.detailsItem.cctvNm"
									:maxLength="maxLength.cctvNm"
								></jarvis-field>
								<!-- 현장명 -->
								<jarvis-field :label="detailsFieldMap.siteNm" field="detailsItem.siteNm" required="true">
									<select2
										v-model="detailsItem.siteId"
										data-vv-name="detailsItem.siteNm"
										v-validate="validationRule.detailsItem.siteId"
										:options="options.siteOptions"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- CCTV 설치 구분 코드 -->
								<jarvis-field :label="detailsFieldMap.cctvInstallDvsnCd" field="detailsItem.cctvInstallDvsnCd" required="true">
									<select2
										v-model="detailsItem.cctvInstallDvsnCd"
										data-vv-name="detailsItem.cctvInstallDvsnCd"
										v-validate="validationRule.detailsItem.cctvInstallDvsnCd"
										:options="options.cctvInstallDvsnCdOptions"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- CCTV 설치 장소 -->
								<jarvis-field
									:label="detailsFieldMap.cctvInstallPlaceCont"
									field="detailsItem.cctvInstallPlaceCont"
									required="true"
									v-model="detailsItem.cctvInstallPlaceCont"
									v-validate="validationRule.detailsItem.cctvInstallPlaceCont"
									data-vv-name="detailsItem.cctvInstallPlaceCont"
									:maxLength="maxLength.cctvInstallPlaceCont"
								></jarvis-field>
								<!-- 사용여부 -->
								<jarvis-field :label="detailsFieldMap.useYn" field="detailsItem.useYn" required="true">
									<select2
										v-model="detailsItem.useYn"
										data-vv-name="detailsItem.useYn"
										v-validate="validationRule.detailsItem.useYn"
										:options="options.ynOptions"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- CCTV 종류 구분 코드 -->
								<jarvis-field :label="detailsFieldMap.cctvKindDvsnCd">
									<select2 v-model="detailsItem.cctvKindDvsnCd" :options="options.cctvKindDvsnCdOptions">
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- CCTV 해상도 -->
								<jarvis-field
									:label="detailsFieldMap.cctvResolution"
									v-model="detailsItem.cctvResolution"
									field="detailsItem.cctvResolution"
									data-vv-name="detailsItem.cctvResolution"
									v-validate="validationRule.detailsItem.cctvResolution"
									:maxLength="maxLength.cctvResolution"
								></jarvis-field>
								<!-- CCTV 접속 URL -->
								<jarvis-field
									:label="detailsFieldMap.cctvConnUrl"
									v-model="detailsItem.cctvConnUrl"
									field="detailsItem.cctvConnUrl"
									data-vv-name="detailsItem.cctvConnUrl"
									v-validate="validationRule.detailsItem.cctvConnUrl"
									:maxLength="maxLength.cctvConnUrl"
								></jarvis-field>
								<!-- CCTV 접속 포트 -->
								<jarvis-field
									:label="detailsFieldMap.cctvConnPort"
									v-model="detailsItem.cctvConnPort"
									field="detailsItem.cctvConnPort"
									data-vv-name="detailsItem.cctvConnPort"
									v-validate="validationRule.detailsItem.cctvConnPort"
									:maxLength="maxLength.cctvConnPort"
								></jarvis-field>
								<!-- CCTV PLAY URL -->
								<jarvis-field
									:label="detailsFieldMap.cctvPlayUrl"
									v-model="detailsItem.cctvPlayUrl"
									field="detailsItem.cctvPlayUrl"
									data-vv-name="detailsItem.cctvPlayUrl"
									v-validate="validationRule.detailsItem.cctvPlayUrl"
									:maxLength="maxLength.cctvPlayUrl"
								></jarvis-field>
							</div>
							<!-- 안전대상물 표시(안전대상물판별여부) -->
							<div class="col-lg-6" style="display: flex; flex-direction: column; height: 628px">
								<div class="col-lg-11 col-lg-offset-1" style="height: 140px; border: 1px solid #ddd; margin-bottom: 35px">
									<i
										style="color: gray; position: absolute; top: 20px"
										class="icon-question4"
										@mouseover="info($event)"
										@mouseleave="closeInfo($event)"
									></i>
									<span class="tooltipText">
										*사용: 대상물이 안전한 상태인 경우에도 표시합니다.
										<br />
										*미사용: 대상물이 안전한 상태인 경우에는 표시하지 않습니다.
									</span>
									<div style="padding: 50px 0px 50px 0px">
										<jarvis-field :label="detailsFieldMap.safeObjectDistigshYn" field="detailsItem.safeObjectDistigshYn" required="true">
											<select2
												v-model="detailsItem.safeObjectDistigshYn"
												:required="true"
												data-vv-name="detailsItem.safeObjectDistigshYn"
												v-validate="validationRule.detailsItem.safeObjectDistigshYn"
												:options="options.ynOptions"
											>
												<option value="">선택</option>
											</select2>
										</jarvis-field>
									</div>
								</div>
								<!-- gpu번호 -->
								<jarvis-field
									:label="detailsFieldMap.gpuNo"
									v-model="detailsItem.gpuNo"
									field="detailsItem.gpuNo"
									required="true"
									data-vv-name="detailsItem.gpuNo"
									v-validate="validationRule.detailsItem.gpuNo"
								></jarvis-field>
								<!-- gpu병렬개수 -->
								<jarvis-field
									:label="detailsFieldMap.gpuParallelCount"
									v-model="detailsItem.gpuParallelCount"
									field="detailsItem.gpuParallelCount"
									required="true"
									data-vv-name="detailsItem.gpuParallelCount"
									v-validate="validationRule.detailsItem.gpuParallelCount"
								></jarvis-field>
								<!-- 미디어서버 id -->
								<jarvis-field
									:label="detailsFieldMap.mdserverId"
									required="true"
									v-model="detailsItem.mdserverId"
									field="detailsItem.mdserverId"
									data-vv-name="detailsItem.mdserverId"
									v-validate="validationRule.detailsItem.mdserverId"
									:maxLength="maxLength.mdserverId"
								></jarvis-field>
								<!-- ML엔진구분코드 -->
								<jarvis-field :label="detailsFieldMap.mlEngineDvsnCd" field="detailsItem.mlEngineDvsnCd" required="true">
									<select2
										v-model="detailsItem.mlEngineDvsnCd"
										data-vv-name="detailsItem.mlEngineDvsnCd"
										v-validate="validationRule.detailsItem.mlEngineDvsnCd"
										:options="options.mlEngineDvsnCdOptions"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>

								<div v-if="isEditMode">
									<!-- 등록일, 수정모드만 표시 -->
									<jarvis-field
										:label="detailsFieldMap.regDtm"
										disabled="true"
										v-if="isEditMode"
										field="detailsItem.regDtm"
										:value="detailsItem.regDtm | toDisplayDateTime"
									></jarvis-field>
									<!-- 등록자, 수정모드만 표시 -->
									<jarvis-field
										:label="detailsFieldMap.regUser"
										disabled="true"
										v-if="isEditMode"
										field="detailsItem.regUser"
										:value="detailsItem.regUser"
									></jarvis-field>
									<!-- 수정일, 수정모드만 표시 -->
									<jarvis-field
										:label="detailsFieldMap.updDtm"
										disabled="true"
										v-if="isEditMode"
										field="detailsItem.updDtm"
										:value="detailsItem.updDtm | toDisplayDateTime"
									></jarvis-field>
									<!-- 수정자, 수정모드만 표시 -->
									<jarvis-field
										:label="detailsFieldMap.updUser"
										disabled="true"
										v-if="isEditMode"
										field="detailsItem.updUser"
										:value="detailsItem.updUser"
									></jarvis-field>
								</div>
							</div>
						</fieldset>
					</div>
					<div>
						<div style="font-size: 12px; color: red; margin-bottom: 10px">
							CCTV 접속 URL, CCTV 접속 포트, CCTV PLAY URL, gpu번호, gpu병렬개수, 미디어서버ID, ML엔진 등의 경우 ML서버 재시작 후 추가 및 수정 내역이
							반영됩니다.
							<br />
							원활한 사용을 위해 CCTV 정보 추가 및 수정 후엔 ML서버를 재시작해주세요.
						</div>
						<div class="text-right" v-if="!isEditMode">
							<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
								<b><i class="icon-cross"></i></b>
								닫기
							</button>
							<button type="button" class="btn btn-labeled bg-primary" @click="createData">
								<b><i class="icon-checkmark3"></i></b>
								저장
							</button>
						</div>
						<div class="text-right" v-if="isEditMode">
							<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
								<b><i class="icon-cross"></i></b>
								닫기
							</button>
							<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteData">
								<b><i class="icon-minus3"></i></b>
								삭제
							</button>
							<button type="button" class="btn btn-labeled bg-primary" @click="updateData">
								<b><i class="icon-checkmark3"></i></b>
								저장
							</button>
						</div>
					</div>

					<span v-if="debug">
						isCreateMode: {{ isCreateMode }}
						<br />
						isEditMode: {{ isEditMode }}
						<br />
						detailsItemOriginal: {{ detailsItemOriginal }}
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
						<br />
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import _ from 'lodash';
import JarvisField from '@/components/JarvisField';
import apiIndex from '../../api/index';

let axiosExtention;
const cctvApi = apiIndex.cctv;

//상세필드검증규칙
const detailsValidationRule = {
	common: {
		//공통
		cctvId: 'max:10|required',
		cctvNm: 'max:50|required',
		siteId: 'max:10|required',
		cctvInstallDvsnCd: 'required',
		cctvResolution: 'numOnly',
		cctvConnUrl: 'max:200',
		cctvConnPort: 'numOnly|max:6',
		cctvPlayUrl: 'max:200',
		useYn: 'required',
		installDtm: 'required',
		cctvInstallPlaceCont: 'max:500|required',
		safeObjectDistigshYn: 'required',
		gpuNo: 'numOnly|required',
		gpuParallelCount: 'numOnly|required',
		mdserverId: 'max:30|required',
		mlEngineDvsnCd: 'required',
	},
	edit: {
		//수정
	},
	create: {
		//생성
	},
};

const maxLength = {
	cctvId: '10',
	cctvNm: '50',
	siteId: '10',
	cctvInstallPlaceCont: '500',
	cctvResolution: '10',
	cctvConnUrl: '200',
	cctvConnPort: '6',
	cctvPlayUrl: '200',
	mdserverId: '30',
};
_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		Select2: Select2,
		'jarvis-field': JarvisField,
		KendoGrid: KendoGrid,
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('cctvPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		if (this.pageParam.loginUserRolecd !== '00') {
			this.isSiteDisabled = true;
		}

		// 기존 라이브러리를 사용하기 위해 변환
		this.pageParam.ynOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});
		this.pageParam.cctvInstallDvsnCdOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});
		this.pageParam.cctvKindDvsnCdOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});
		this.pageParam.mlEngineDvsnCdOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});

		//기본 현장 세팅
		this.searchVM.siteNm = this.pageParam.siteOptions.filter(e => e.siteId == this.pageParam.loginUserSiteId)[0].siteNm;
		this.pageParam.siteOptions.forEach(e => {
			e.text = e.siteNm;
			e.value = e.siteId;
		});
		this.options = {
			ynOptions: this.pageParam.ynOptions,
			cctvInstallDvsnCdOptions: this.pageParam.cctvInstallDvsnCdOptions,
			cctvKindDvsnCdOptions: this.pageParam.cctvKindDvsnCdOptions,
			siteOptions: this.pageParam.siteOptions,
			mlEngineDvsnCdOptions: this.pageParam.mlEngineDvsnCdOptions,
		};
		this.gridColumns.filter(item => item.field == 'cctvInstallDvsnCd')[0].mapData = this.options.cctvInstallDvsnCdOptions;
		this.gridColumns.filter(item => item.field == 'cctvKindDvsnCd')[0].mapData = this.options.cctvKindDvsnCdOptions;
		this.gridColumns.filter(item => item.field == 'useYn')[0].mapData = this.options.ynOptions;

		this.$validator.localize('ko', { attributes: this.$jarvisExtention.addKeyPrefix(this.detailsFieldMap, 'detailsItem.') });

		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.search();
	},
	mounted() {
		this.$nextTick(function () {
			this.focusFirstInput('startInput');
		});
	},
	data: () => ({
		pageParam: null,
		//API목록
		apiUrl: {
			pageListApi: cctvApi.inqCctv,
			detailApi: cctvApi.inqOneCctv,
			delApi: cctvApi.delCctv,
			excelApi: cctvApi.exlCctv,
			insApi: cctvApi.insCctv,
			updApi: cctvApi.updCctv,
		},
		//검색VM
		searchVM: {
			siteNm: null,
			cctvNm: null,
			useYn: '1', // 사용
		},
		options: {},
		searchState: {}, //적용된 검색조건
		//그리드설정
		gridColumns: [
			{ field: 'siteNm', title: '현장명', width: '12%' },
			{ field: 'cctvNm', title: 'CCTV 명', width: '12%' },
			{
				field: 'cctvInstallDvsnCd',
				title: '설치구분',
				width: '6%',
				dataFormat: 'optionMap',
				align: 'center',
			},
			{
				field: 'cctvKindDvsnCd',
				title: '종류구분',
				width: '6%',
				dataFormat: 'optionMap',
				align: 'center',
			},
			{
				field: 'cctvResolution',
				title: '해상도',
				width: '4%',
				align: 'right',
				dataFormat: 'numberFormat',
			},
			{
				field: 'cctvConnUrl',
				title: 'CCTV 접속 URL',
				align: 'left',
			},
			{
				field: 'cctvConnPort',
				title: '접속 포트',
				width: '6%',
				align: 'right',
			},
			{
				field: 'useYn',
				title: '사용여부',
				width: '5%',
				align: 'center',
				dataFormat: 'optionMap',
			},
			{
				field: 'regDtm',
				title: '등록일',
				width: '6%',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
			},
			{
				field: 'updDtm',
				title: '수정일',
				width: '6%',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
			},
		],
		//상세필드명맵
		detailsFieldMap: {
			cctvId: 'CCTV ID',
			cctvNm: 'CCTV명',
			siteNm: '현장명',
			cctvInstallDvsnCd: 'CCTV 설치 구분 코드',
			cctvKindDvsnCd: 'CCTV 종류 구분 코드',
			cctvResolution: 'CCTV 해상도',
			cctvConnUrl: 'CCTV 접속 URL',
			cctvConnPort: 'CCTV 접속 포트',
			cctvPlayUrl: 'CCTV PLAY URL',
			useYn: '사용 여부',
			installDtm: '설치 일시',
			cctvInstallPlaceCont: 'CCTV 설치 장소',
			regDtm: '등록일',
			regUser: '등록자',
			updDtm: '수정일',
			updUser: '수정자',
			safeObjectDistigshYn: '안전대상물표시',
			gpuNo: 'gpu번호',
			gpuParallelCount: 'gpu병렬개수',
			mdserverId: '미디어서버ID',
			mlEngineDvsnCd: 'ML엔진구분',
		},
		//인풋 최댓값 설정
		maxLength,
		detailsItemOriginal: {}, //상세편집 원본
		detailsItem: {}, //상세편집 수정본
		errorInstallDtm: '',
		debug: false,
		isSiteDisabled: false,
	}),
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			var rule = null;
			if (this.isEditMode) {
				rule = detailsValidationRule.edit;
			} else {
				rule = detailsValidationRule.create;
			}
			return { detailsItem: rule };
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
		// isCreateMode() {
		// 	let element = document.getElementsByClassName('inputDiv1')[0];
		// 	if (this.isCreateMode) {
		// 		element.classList.add('col-lg-offset-3');
		// 	} else {
		// 		element.classList.remove('col-lg-offset-3');
		// 	}
		// },
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		// info tooltip 제거
		closeInfo(event) {
			let element = event.srcElement.nextElementSibling;
			element.style.visibility = 'hidden';
		},
		// info tooltip 생성
		info(event) {
			let element = event.srcElement.nextElementSibling;
			element.style.visibility = 'visible';
		},
		// 첫번째 인풋창에 포커스 두도록
		focusFirstInput(inputId) {
			document.getElementById(inputId).focus();
		},
		checkInstallDtmValue() {
			if (this.detailsItem.installDtm == null || this.detailsItem.installDtm == '') {
				this.errorInstallDtm = 'reqInstallDtd';
			} else {
				this.errorInstallDtm = '';
			}
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			this.$nextTick(function () {
				this.loadGrid();
			});
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		//엑셀다운로드
		downloadExcel() {
			this.$axios({
				url: this.apiUrl.excelApi,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName = 'CCTV관리_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			this.errorInstallDtm = '';
			if (!_.isEmpty(selectedRowItem)) {
				this.$axios
					.post(cctvApi.inqOneCctv, { cctvId: selectedRowItem.cctvId })
					.then(
						function (response) {
							this.detailsItemOriginal = $.extend(true, {}, response.data);
							this.detailsItem = $.extend(true, {}, response.data);
							this.detailsItem.installDtm = this.detailsItem.cctvInstallVO.installDtm;
							// this.detailsItem.cctvInstallPlaceCont = this.detailsItem.cctvInstallVO.cctvInstallPlaceCont;

							//data에서 미리 선언되어 있지 않은 변수에 값을 할당해 바인딩하기 위해 set해줌.
							this.$set(this.detailsItem, 'cctvInstallPlaceCont', this.detailsItem.cctvInstallVO.cctvInstallPlaceCont);
							setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
		//데이터 추가 시작
		startCreate() {
			this.errorInstallDtm = '';
			this.detailsItemOriginal = {};
			this.detailsItem = {
				cctvNm: null,
				siteId: null,
				cctvInstallDvsnCd: null,
				cctvKindDvsnCd: null,
				cctvResolution: null,
				cctvConnUrl: null,
				cctvConnPort: null,
				cctvPlayUrl: null,
				useYn: 1,
				installDtm: null,
				cctvInstallPlaceCont: null,
				cctvInstallVO: {
					installDtm: null,
					cctvInstallPlaceCont: null,
				},
				safeObjectDistigshYn: 1,
				gpuNo: null,
				gpuParallelCount: null,
				mdserverId: null,
			};
			setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
		},
		//신규저장
		createData() {
			this.$validator.validateAll().then(success => {
				this.checkInstallDtmValue();

				if (success === false) {
					return;
				}
				this.detailsItem.cctvInstallVO.installDtm = this.detailsItem.installDtm;
				this.detailsItem.cctvInstallVO.cctvInstallPlaceCont = this.detailsItem.cctvInstallPlaceCont;

				this.$axios
					.post(this.apiUrl.insApi, this.detailsItem)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			});
		},
		//수정저장
		updateData() {
			this.$validator.validateAll().then(success => {
				if (success === false) {
					return;
				}
				if (this.detailsItem.installDtm == null || this.detailsItem.installDtm == '') {
					return;
				}
				this.detailsItem.cctvInstallVO.installDtm = this.detailsItem.installDtm;
				this.detailsItem.cctvInstallVO.cctvInstallPlaceCont = this.detailsItem.cctvInstallPlaceCont;

				if (confirm('수정한 데이터를 저장하시겠습니까?')) {
					this.$axios
						.post(this.apiUrl.updApi, this.detailsItem)
						.then(
							function (r) {
								r;
								this.loadGrid();
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
				}
			});
		},
		//삭제
		deleteData() {
			if (!confirm('해당 데이터를 영구적으로 삭제하시겠습니까?')) {
				return;
			}
			this.$axios
				.post(this.apiUrl.delApi, this.detailsItem)
				.then(
					function (r) {
						r;
						this.loadGrid();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
	},
};
</script>

<style scoped>
.tooltipText {
	width: 360px;
	background-color: gray;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	top: 40px;
	z-index: 1;
	visibility: hidden;
}

[v-cloak] > * {
	display: none !important;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	width: 60%;
	margin-top: 5px;
	max-width: 130px;
}

.table th {
	background: #eee;
	vertical-align: middle !important;
	font-weight: bold;
}
.panel-body .table th:first-child {
	width: 120px;
	text-align: center;
}

.table td {
	vertical-align: middle;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 1px;
}

.panel-body {
	text-align: center;
}

.panel-body .table td:first-child {
	text-align: center;
}
.panel-body .table td:last-child {
	text-align: center;
	width: 80px;
}

.in-panel-body {
	display: inline-block;
	width: 1200px;
}

.table [empty] {
	display: none;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}

.itemWrapper > label {
	margin-top: 7px;
}

.itemWrapper.reqInstallDtd .msg:before {
	content: '\ed63';
	font-family: 'icomoon';
	font-size: 16px;
	position: absolute;
	top: 4.3vh;
	left: 0.7vw;
}

.itemWrapper.reqInstallDtd > div > input {
	border: 1px solid #d84315;
}

.required,
.itemWrapper.reqInstallDtd > label {
	color: #d84315;
}
.itemWrapper.reqInstallDtd > div .msg {
	margin-top: 7px;
	color: #f44336;
	display: block;
}
.itemWrapper.reqInstallDtd .msg:after {
	margin-left: 1rem;
	content: '설치일시는 필수 정보입니다.';
}
</style>
